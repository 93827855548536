import React from 'react';
import css from 'styled-components';

import ImgBg from '../../images/honor/bg.png';
import Img1 from '../../images/honor/1.png';
import Img2 from '../../images/honor/2.png';

const Wrap = css.div`
  padding: 100px 0;
  background: url(${ImgBg}) no-repeat left -280px / 25%;
  border-top: 40px solid rgba(0, 0, 0, 0.009);
  h1{
    font-size: 40px;
    font-weight: 600;
    margin: 0 0 70px;
  }
  .cont{
    display: flex;
    justify-content: space-between;
    .bystack{
      width: 560px;
      img{
        width: 100%;
      }
      p{
        text-align: center;
      }
      p.title{
        font-size: 20px;
        line-height: 2.2;
        font-weight: 400;
        span:first-child{
          color: rgba(0, 78, 228, 1);
        }
        span:last-child{
          color: rgba(145, 211, 3, 1);
        }
      }
      p{
        font-size: 16px;
        font-weight: 300;
      }
    }
    >img{
      width: 455px;
    }
  }
`;

const Honor = () => {
  return (
    <Wrap>
      <div className="page-container">
        <h1>荣誉资质</h1>
        <div className="cont">
          <div className="bystack">
            <img src={Img1} alt="" />
            <p className='title'>
              <span>「 </span>
              <span>Bystack 已通过网信办区块链信息服务备案</span>
              <span> 」</span>
            </p>
            <p>（浙网信办330100619213211320012号）</p>
          </div>
          <img src={Img2} alt="" />
        </div>
      </div>
    </Wrap>
  );
};

export default Honor;
