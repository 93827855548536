import React from 'react';
import css from 'styled-components';

import ImgBg from '../../images/support/bg.png';
import Img1 from '../../images/support/1.png';
import Img2 from '../../images/support/2.png';
import Img3 from '../../images/support/3.png';
import Img4 from '../../images/support/4.png';

const Wrap = css.div`
  width: 100%;
  padding: 100px 0 100px;
  background: url(${ImgBg}) no-repeat left 10% / 25%;
  .page-container{
    >h1{
      font-size: 40px;
      font-weight: 600;
      line-height: 120%;
      color: rgba(0,0,0,.88);
    }
    >p{
      font-size: 18px;
      font-weight: 400;
      margin-top: 20px;
    }
    div.cont{
      display: flex;
      flex-wrap: wrap;
      margin-top: 70px;
      .item{
        display: flex;
        width: 550px;
        height: 174px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.04), 0px 4px 30px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        margin: 40px 0 0 0;
        padding: 20px;
        box-sizing: border-box;
        background: #fff;
        &:nth-child(2n){
          margin-left: 40px;
        }
        .item-cont{
          width: 310px;
          h2{
            font-size: 20px;
            color: rgba(0, 0, 0, 0.72);
          }
          p{
            font-size: 16px;
            color: rgba(0, 0, 0, 0.56);
            margin-top: 10px;
            font-weight: 300;
            line-height: 180%;
          }
        }
        img{
          height: 96px;
          margin: 20px 0 0 80px;
        }
      }
    }
  }
`;

const list = [
  {
    title: '区块链方案',
    cont: 'Bystack专注于区块链核心技术研发，具备从底层许可链到上层应用的全产业链产品开发能力，为产业客户提供一站式解决方案',
    img: Img1,
  },
  {
    title: '区块链部署',
    cont: '区块链系统快速部署，区块链配套设施云服务采购',
    img: Img2,
  },
  {
    title: 'DAPP开发 ',
    cont: '完善的区块链开发生态，智能合约定制化服务',
    img: Img3,
  },
  {
    title: '媒体推广',
    cont: '专业媒体团队和推广渠道，助力区块链方案落地',
    img: Img4,
  },
];

const Support = () => {
  return (
    <Wrap>
      <div className="page-container">
        <h1 style={{ marginBottom: 30 }}>为您提供全流程技术支持</h1>
        <p>为更多企业提供专业、优质的区块链技术解决方案</p>
        <div className="cont">
          {list.map((item, index) => (
            <div key={index} className="item">
              <div className="item-cont">
                <h2>{item.title}</h2>
                <p>{item.cont}</p>
              </div>
              <img src={item.img} alt="" />
            </div>
          ))}
        </div>
      </div>
    </Wrap>
  );
};

export default Support;
