import React from 'react';
import css from 'styled-components';

import ImgBg from '../../images/eco/bg.png';
import ImgBg2 from '../../images/honor/bg.png';
import Img1 from '../../images/eco/1.png';
import Img2 from '../../images/eco/2.png';
import Img3 from '../../images/eco/3.png';

const Wrap = css.div`
  width: 100%;
  padding: 100px 0;
  background: url(${ImgBg}) no-repeat right 60% / 25%, url(${ImgBg2}) no-repeat left 980px / 25%;
  border-top: 40px solid rgba(0, 0, 0, 0.009);
  .page-container{
    display: flex;
    justify-content: space-between;
    .left-cont{
      width: 660px;
      >h1{
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 130px;
      }
    }
    .right-cont{
      width: 430px;
    }
  }
  .item{
    img{
      width: 100%;
    }
    h2{
      font-size: 24px;
      font-weight: 400;
      margin-top: 50px;
    }
    p{
      font-size: 20px;
      line-height: 120%;
      margin: 16px 0 30px;
      font-weight: 300;
    }
    a{
      color: #004EE4;
    }
  }
  .item + .item{
    margin-top: 100px;
  }
`;

const EcoItem = ({ data }) => (
  <div className="item">
    <img src={data.img} alt="" />
    <h2>{data.title}</h2>
    <p>{data.cont}</p>
    {/* <a href={data.link}>了解更多 →</a> */}
  </div>
);

const Eco = () => {
  return (
    <Wrap>
      <div className="page-container">
        <div className="left-cont">
          <h1>选择您的区块链生态</h1>
          <EcoItem
            data={{
              title: '链上存证服务快速搭建 ',
              cont: '数字版权存证上链，操作便捷真实可信，上链数据可核验，加强证据效力',
              img: Img1,
              link: 'https://docs.bystack.com/zh/guide/04_case_wujiebantu.html',
            }}
          />
        </div>
        <div className="right-cont">
          <EcoItem
            data={{
              title: '数字藏品一站式服务 ',
              cont: '用区块链技术为艺术作品提供安全可靠易用的数字版权登记和拍卖平台',
              img: Img2,
              link: 'https://docs.bystack.com/zh/guide/04_case_copyright.html',
            }}
          />
          <EcoItem
            data={{
              title: '让版权保护更专业 ',
              cont: '直通互联网法院司法认证的区块链版权存证服务，为每一层服务提供更加安全可信的基石',
              img: Img3,
              link: 'https://docs.bystack.com/zh/guide/04_case_bystack.html',
            }}
          />
        </div>
      </div>
    </Wrap>
  );
};

export default Eco;
