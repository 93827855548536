import React from 'react';
import css from 'styled-components';

import ImgBg from '../../images/developer/bg.png';
import Img1 from '../../images/developer/1.png';
import Img2 from '../../images/developer/2.png';
import Img3 from '../../images/developer/3.png';

const list = [
  {
    title: 'Bystack助力无界版图创作者经济',
    cont: '互联网的出现改变了传统媒体的传播方式，将少数公司垄断的媒体和娱乐逐渐拆解开，使得其更加的去中心化',
    img: Img1,
    link: 'https://docs.bystack.com/zh/guide/04_case_wujiebantu.html',
  },
  {
    title: '区块链版权有什么优势？',
    cont: '传统版权登记价格高，流程长，自动化不足，需要耗费人工，时间成本长。而使用区块链后，自动标记了藏品创建者和所有者。',
    img: Img2,
    link: 'https://docs.bystack.com/zh/guide/04_case_copyright.html',
  },
  {
    title: '什么是Bystack区块链',
    cont: 'Bystack是区块链一站式的服务平台，为企业提供专业、优质的区块链技术解决方案，快速构建和实现数据区块链化。',
    img: Img3,
    link: 'https://docs.bystack.com/zh/guide/04_case_bystack.html',
  },
];

const Wrap = css.div`
  padding: 100px 0;
  border-top: 40px solid rgba(0, 0, 0, 0.009);
  background: url(${ImgBg}) no-repeat right -40px / 25%;
  h1{
    font-size: 40px;
    font-weight: 600;
    margin: 0 0 70px;
  }
  .cont{
    display: flex;
    justify-content: space-between;
    .item{
      width: 350px;
      img{
        width: 350px;
        height: 180px;
        border-radius: 8px;
      }
      h2{
        font-size: 20px;
        line-height: 140%;
        font-weight: 500;
        margin: 10px 0;
      }
      p{
        font-size: 18px;
        font-weight: 300;
        margin: 10px 0;
        line-height: 160%;
      }
      a{
        color: #004EE4;
        font-size: 14px;
      }
    }
  }
`;

const Developer = () => {
  return (
    <Wrap>
      <div className="page-container">
        <h1>开发者案例</h1>
        <div className="cont">
          {list.map((item, index) => (
            <div className="item" key={index}>
              <img src={item.img} alt="" />
              <h2>{item.title}</h2>
              <p>{item.cont}</p>
              <a target="_blank" href={item.link} rel="noreferrer">
                了解更多 →
              </a>
            </div>
          ))}
        </div>
      </div>
    </Wrap>
  );
};

export default Developer;
