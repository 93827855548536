import React from 'react';
import axios from 'axios';
import useSwr from 'swr';
import { Popover } from 'antd';
import 'antd/dist/antd.css';

import imgWechat from '../../images/wx.png';

import css from 'styled-components';

import ImgWechat from '../../images/icons/wechat.png';
import ImgApi from '../../images/icons/doc.png';

const Wrap = css.div`
  width: 620px;
  height: 570px;
  padding-top: 32px;
  >h1{
    color: #fff;
    font-size: 68px;
    line-height: 120%;
  }
  >p{
    color: #fff;
    font-size: 18px;
    line-height: 160%;
    margin: 32px 0 40px;
  }
  .stats{
    display: flex;
    text-align: center;
    color: #fff;
    li{
      >span{
        display: block;
      }
      span:first-child{
        font-size: 16px;
        font-weight: 400;
        color: rgba(255,255,255,.8);
      }
      span:last-child{
        font-size: 28px;
        margin-top: 8px;
      }
      & + li{
        margin-left: 100px;
      }
    }
  }
  .btn-group{
    display: flex;
    margin: 42px 0 0 0;
  }
  .button{
    width: 160px;
    height: 48px;
    text-align: center;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 50px;
    cursor: pointer;
    transition: all .2s;
    &:hover{
      opacity: .8;
    }
    img{
      width: 26px;
      margin-right: 10px;
    }
    &.primary{
      background-color: #fff;
      color: #000;
    }
  }
  .button + .button{
    margin-left: 32px;
  }
`;

const InfoBanner = () => {
  const { data: info } = useSwr('https://bcapi.movapi.com/bystack_scan/chainBoard', (url) => axios.get(url));
  return (
    <div className="page-container">
      <Wrap>
        <h1>
          比原栈
          <br />
          区块链一站式服务平台
        </h1>
        <p>
          Bystack服务于数宇藏品和数字版权的区块链开放平台。
          <br />
          落地应用已经涵盖政务、民生、数宇藏品、版权保护、公益、教育等诸多领域
        </p>
        <ul className="stats">
          <li>
            <span>发行资产</span>
            <span>{info?.data?.data?.asset_count || '-'}</span>
          </li>
          <li>
            <span>交易数</span>
            <span>{info?.data?.data?.transaction_count || '-'}</span>
          </li>
          <li>
            <span>地址数</span>
            <span>{info?.data?.data?.address_count || '-'}</span>
          </li>
        </ul>
        <div className="btn-group">
          <Popover
            content={
              <div>
                <img width="150" src={imgWechat} alt="" />
              </div>
            }
          >
            <a className="button primary">
              <img src={ImgWechat} alt="" />
              联系企业微信
            </a>
          </Popover>
          <a
            href="https://docs.bystack.com/zh/guide/03_gateway_api.html"
            target="_blank"
            className="button"
            rel="noreferrer"
          >
            <img src={ImgApi} alt="" />
            API 技术文档
          </a>
        </div>
      </Wrap>
    </div>
  );
};

export default InfoBanner;
