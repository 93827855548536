import React from 'react';
import css from 'styled-components';

import Img1 from '../../images/partner.png';

const Wrap = css.div`
  border-top: 40px solid rgba(0, 0, 0, 0.009);
  padding: 100px 0;
  img{
    width: 100%;
  }
`;

const Partner = () => {
  return (
    <Wrap>
      <div className="page-container">
        <h1>Bystack合作伙伴</h1>
        <img src={Img1} alt="" />
      </div>
    </Wrap>
  );
};

export default Partner;
