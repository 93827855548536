import React from 'react';
import Layout from '../components/layout/Layout';
import css from 'styled-components';

import InfoBanner from '../components/home/InfoBanner';
import Support from '../components/home/Support';
import Eco from '../components/home/Eco';
import Honor from '../components/home/Honor';
import Developer from '../components/home/Developer';
import Partner from '../components/home/Partner';

import imgBg from '../images/background.jpg';

const Wrap = css.div`
  width: 100%;
  background: url(${imgBg}) center -100px / 100% 720px no-repeat;
  .page-container{
    width: 1160px;
    margin: 0 auto;
    h1{
      font-size: 40px;
      font-weight: 600;
      margin: 0 0 70px;
    }
  }
`;

const IndexPage = () => {
  return (
    <Layout>
      <Wrap>
        <InfoBanner />
        <Support />
        <Eco />
        <Honor />
        <Developer />
        <Partner />
      </Wrap>
    </Layout>
  );
};

export default IndexPage;

export const Head = () => <title>Bystack - 比原栈</title>;
